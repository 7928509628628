import { Component, OnInit } from '@angular/core';
import { CustomTranslatorService } from '../shared/services/custom-translator/custom-translator.service';

/**
 *
 */
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  constructor(public customTranslatorService: CustomTranslatorService) {}

  /**
   *
   */
  ngOnInit(): void {}

  /**
   *
   * @param url
   */
  learnMore(url: string): void {
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location.href = url;
  }
}
