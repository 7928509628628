/* eslint-disable @typescript-eslint/naming-convention */
export interface RolePatch {
  roleItems: RoleItem[];
}

export interface RoleItem {
  operationType: string;
  roleId: string;
  customers: string[];
}

export interface XGSRole {
  id: string;
  name: string;
  description: string;
  permissions: string[];
  customerId?: string;
}

export interface XGSCustomersAPI {
  resultMessage: string;
  customers: XGSCustomer[];
}

export interface XGSCustomer {
  name: string;
  address: string;
  customerId: string;
  parentCustomerId: string;
  parentCustomerIds: string[];
  childrenCustomerIds: string[];
  phoneNumber: string;
  email: string;
  primaryContactUserId: string;
  xylemPrimaryContactId: string;
  status: string;
  properties: XGSCustomerProperties;
}

export interface XGSCustomerProperties {
  timezone: string;
}

export interface UserPermissionModel {
  customer: XGSCustomer;
  role: XGSRole;
}

export interface CountryModel {
  fips: string;
  iso: string;
  iso3: string;
  name: string;
  numeric: number;
  reference: Object;
  regions: RegionModel[];
}

export interface RegionModel {
  admin: string;
  fips: string;
  iso: string;
  name: string;
  reference: Object;
}

export interface XGSUsers {
  _embedded: XGSUsersEmbed;
  _links?: XGSUsersLinks;
  page?: XGSPage;
}

export interface XGSUsersLinks {
  first: XGSUserLink;
  prev: XGSUserLink;
  self: XGSUserLink;
  last: XGSUserLink;
}

export interface XGSUserLink {
  href: string;
}

export interface XGSUser {
  address?: string;
  alternate_emails?: string[];
  alternate_phone_numbers?: string[];
  email: string;
  family_name: string;
  given_name: string;
  id: string;
  multifactor?: unknown[];
  name: string;
  phone_number?: string;
  phoneNumber?: string;
  platformRoles?: unknown[];
  primary_email?: string;
  primary_phone_number?: string;
  roles: XGSUserClientRole;
  tc_accepted?: boolean;
  user_metadata: {
    locale: string;
  };
  lastLoggedinApp?: string;
  timeZone?: string;
  additionalProperties?: Object;
  last_login?: string;
}

export interface XGSUserClientRole {
  [key: string]: XGSUserRoles;
}

export interface XGSUserRoles {
  [key: string]: string[];
}

export interface XGSUsersEmbed {
  usersDToes: XGSUser[];
}

export interface XGSPage {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface UDLUserData {
  givenName: string;
  familyName: string;
  email: string;
  id: string;
  name: string;
  xgsRoles: XGSUserRoles;
  xgsPermissions: XGSRole[];
  user_metadata?: {
    locale: string;
  };
}

export interface UtilityCustomers {
  customerList: UtilityOption[];
  selectedCustomer: UtilityOption;
}

export interface UtilityOption {
  name: string;
  value: UtilityOptionValue;
  disabled: boolean;
}

export interface UtilityOptionValue {
  customer: XGSCustomer;
  access?: string[];
}

export interface UserAuditItem {
  changed_by: string;
  user_altered: string;
  user_action: string;
  initial_value: string;
  altered_value: string;
  alteration_timestamp: string;
  success: string;
  customer_id: string[];
  event_id?: string;
}

export interface UserAuditRequest {
  auditItems: UserAuditItem[];
}

export interface PermissionModel {
  html: string;
  permission: string;
  set: boolean;
}

export interface RedshiftRole {
  roleArn: string;
  customers: string[];
}

export enum ExdlPermissions {
  SCHEDULER = 'Scheduler Access',
  CREATE_USERS = 'Create Users',
  EDIT_USERS = 'Edit Users',
  DELETE_USERS = 'Delete Users',
  ACCESS_USERS = 'Access All Users',
  EDIT_ROLES = 'Edit Roles',
  CREATE_ROLES = 'Create Roles',
  RESOURCE_USAGE = 'Resource Usage Access',
  CUSTOMER_ONBOARDING = 'Access Customer Onboarding',
  EDIT_CUSTOMER = 'Edit Customer Onboarding',
  DATA_IMPORT = 'Data Import Access',
  APP_STORE = 'Appstore Access',
  CLUSTER_ANALYSIS = 'Cluster Analysis Access',
  ANOMALY_DETECTION = 'Anomaly Detection Access',
  BUSINESS_RULE_ENGINE = 'BRE Access',
  HEALTH_MONITOR = 'Health Monitor Access'
}

export enum XgsOxiRoles {
  APP_ADMIN = 'OXI:APPLICATIONADMIN',
  APP_USER = 'OXI:APPLICATIONUSER',
  CUSTOMER_ADMIN = 'OXI:CUSTOMERADMIN'
}

export enum ExdlRoles {
  LOCAL_ADMIN = 'LocalAdmin',
  DISTRIBUTOR = 'Distributor',
  PROFESSIONAL_SERVICES = 'ProfessionalServices',
  SUPER_ADMIN = 'SuperAdmin',
  TECH_SERVICES = 'TechServices',
  POWER_USER = 'PowerUser',
  READ_ONLY = 'ReadOnly',
  XYLEM_CLIENT_SUCCESS = 'XylemClientSuccess'
}

export enum ExdlSupersetRoles {
  VIEW_CHARTS = 'SupersetViewCharts',
  CREATE_DATASETS = 'SupersetCreateDatasets',
  CUSTOMER_ACCESS = 'SupersetCustomerAccess',
  VIEW_DASHBOARDS = 'SupersetViewDashboards',
  ADMIN = 'SupersetAdmin',
  SQL_LAB = 'SupersetSqlLab',
  CREATE_DASHBOARDS = 'SupersetCreateDashboards',
  CREATE_CHARTS = 'SupersetCreateCharts'
}

export enum OidcConfigIds {
  XGS = 'xgs'
}

export enum OidcCommonValues {
  TAB_DETECTION = 'state'
}
