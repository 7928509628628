import { LogLevel } from 'angular-auth-oidc-client';
import { FeatureFlags } from '../app/shared/services/feature-flags/feature-flags.service';

/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: false,
  environmentName: 'dev',
  localhostRedirect: false,
  systemInfoUrl: '',
  appName: 'Xylem Data Lake',
  apiBaseUrl: '/gravity-angular-ui/svc/',
  request: {
    headers: {
      'Content-Type': 'application/json',
      'APP-ID': 'gravity-angular-ui'
    },
    withCredentials: true
  },
  supersetUrl: 'https://superset.dev.utilitydatalake.com/sync',
  xgsClientID: '96eec5ed-f535-497e-88f1-a5d16f77ebf7', // qa cluster
  goAiguaClientID: 'xylem-data-lake',
  odbcBucket: '851485739273-us-east-1-odbc-redshift-driver',
  unloadBucket: '851485739273-us-east-1-redshift-unload',
  dataImportBucket: '851485739273-<<REGION>>-customer-data-imports',
  domain: 'dev.utilitydatalake.com',
  xgsIdp: 'cloudfront-qa.xylem-cloud.com/xgs/auth/realms/xgsum',
  xgsBaseURL: 'https://cloudfront-qa.xylem-cloud.com/xgs',
  goAiguaIdp: 'auth-eu.go-aigua.com/auth/realms/xpre',
  goAiguaRedirect: 'portal-xpre.xylemvue.goaigua.com',
  cognitoIdp: 'us-east-1:92277bcc-a257-46a7-b4ad-51038eeb5465',
  logLevel: LogLevel.Error,
  maxS2SLogins: 5,
  idleTimeoutMinutes: 28,
  idleWarningMinutes: 2,
  dataUsageLimit: 536870912000,
  dataUsageWarning: 402653184000,
  xgsUsersPageSize: 275,
  dataDogAppID: '3a4a4f23-887c-445d-ac23-1699b6ccc321',
  dataDogClientToken: 'pub04877eb0d62c3eb34023cb8ebb689dea',
  dataDogEnv: 'qa',
  localeExpirationSeconds: 1800,
  featureFlags: {
    DATA_IMPORT: true,
    HEALTH_MONITOR: true,
    CLUSTER_ANALYSIS: true,
    APP_STORE: true,
    BUSINESS_RULE_ENGINE: true
  } as FeatureFlags
};
