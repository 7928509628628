import { Injectable, OnDestroy } from '@angular/core';
import {
  UDLUserData,
  UtilityCustomers,
  UtilityOption,
  UtilityOptionValue,
  XGSCustomer,
  XGSUser
} from '@common/models/user-management.model';
import { NotificationsService } from '@common/notifications/notifications.service';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AlertsService } from '@gravity-angular/base';
import { ColorType } from '@gravity-angular/models';
import { SupersetService } from '../../../superset/superset-service/superset.service';
import { ConfirmationDialogComponent } from '../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { UserFunctionsService } from '../../../user-management/user/user-functions-service/user-functions.service';
import { AmplifyService } from '../../../aws/amplify/amplify.service';
import { CustomerConfig } from '@common/models/customer-config';
import { ApiResponse } from '@common/models/api.model';
import { DatadogService } from '../datadog-services/datadog.service';
import { StateService } from '../state-services/state.service';
import { FeatureFlag } from '../feature-flags/feature-flags.service';
import { CustomerSession } from '@common/models/session-data.model';

/**
 * Service for handling customer-related functionality, such as customer selection and configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomerService implements OnDestroy {
  selectedCustomer: UtilityOptionValue;
  currentCustomer: Observable<UtilityOptionValue>;
  udlCustomersConfig: CustomerConfig[] = [];
  utilityOptions = new BehaviorSubject<UtilityOption[]>([
    {
      name: ' ',
      value: { customer: {} as XGSCustomer },
      disabled: false
    }
  ]);

  private readonly allCustomerOption: UtilityOption = {
    name: 'All Customers',
    value: {
      customer: {
        customerId: 'All Customers',
        name: 'AllCustomers'
      } as XGSCustomer
    },
    disabled: false
  };

  private readonly selectedCustomerSource = new BehaviorSubject({
    customer: { customerId: '' } as XGSCustomer
  } as UtilityOptionValue);

  private storageSubscription: Subscription;
  private customerDebounceTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor(
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
    private readonly cookieService: CookieService,
    private readonly supersetService: SupersetService,
    private readonly stateService: StateService,
    private readonly alertsService: AlertsService,
    private readonly userFunctionsService: UserFunctionsService,
    private readonly datadogService: DatadogService,
    private readonly router: Router,
    private readonly amplifyService: AmplifyService
  ) {
    this.currentCustomer = this.selectedCustomerSource.asObservable();
    this.crossTabCustomerChange();
  }

  /**
   * Updates Observable with newly selected customer
   * @param customer XGS Customer
   */
  changeSelectedCustomer(customer: UtilityOptionValue): void {
    this.selectedCustomerSource.next(customer);
  }

  /**
   * Passes updated utility options to observable
   * @param options updated utility options
   */
  changeUtilityOptions(options: UtilityOption[]): void {
    this.utilityOptions.next(options);
  }

  /**
   * Resets utility options to remove 'All Customers' option
   * and sets to first customer in list if All Customers is selected
   */
  resetUtilityOptions(): void {
    const options = this.utilityOptions.getValue().filter(option => {
      if (option.name !== this.allCustomerOption.name) {
        return option;
      }
    });

    const customer =
      this.selectedCustomer.customer.customerId === this.allCustomerOption.name
        ? options[0].value
        : this.selectedCustomer;
    this.onUtilityChanged(customer);
    this.changeUtilityOptions(options);
  }

  /**
   * Adds 'All Customers' to utility options for Xylem users
   * @param user XGS User
   */
  addAllCustomers(user: XGSUser): void {
    const options = this.utilityOptions.getValue();

    if (this.validAllCustomersInsertion(options, user)) {
      options.unshift(this.allCustomerOption);

      this.changeUtilityOptions(options);
    }
  }

  /**
   * Verify if 'All Customers' should be added to utility options
   * @param utilityOptions - list of utility options
   * @param user - XGS User metadata
   * @returns boolean indicating if 'All Customers' should be added
   */
  validAllCustomersInsertion(
    utilityOptions: UtilityOption[],
    user: XGSUser
  ): boolean {
    if (
      !user ||
      !this.userFunctionsService.xylemRoleCheck(
        user.roles[environment.xgsClientID]
      )
    ) {
      return false;
    }

    const allOptionPresent = utilityOptions.some(option => {
      return (
        option.value.customer.customerId ===
        this.allCustomerOption.value.customer.customerId
      );
    });

    return !allOptionPresent;
  }

  /**
   * Builds Utility options and grabs first
   * customer in list to use as potential selected customer
   * @param customers list of UDL's customers from XGS
   * @param user XGS User metadata
   * @returns object containing list of utility options and first customer in list
   */
  buildCustomerList(customers: XGSCustomer[], user: XGSUser): UtilityCustomers {
    let customerPayload: UtilityCustomers = {
      selectedCustomer: {
        name: '',
        value: { customer: {} as XGSCustomer },
        disabled: false
      },
      customerList: []
    };
    for (const customer of customers) {
      if (customer.customerId === 'AllCustomers' && !this.selectedCustomer) {
        // NOTE: This is temporary until latency issue is resolved from XGS to get customers.
        customerPayload.customerList = this.buildAllCustomersList(customer);
        break;
      }

      if (customer.customerId !== 'AllCustomers') {
        const payload = this.buildCustomerPayload(customer);
        customerPayload.customerList.push(payload);
      }
    }

    if (
      this.router.url.includes('health-Dashboard') &&
      this.validAllCustomersInsertion(customerPayload.customerList, user)
    ) {
      customerPayload.customerList.unshift(this.allCustomerOption);
    }

    customerPayload = this.finalizeCustomerPayload(customerPayload);

    return customerPayload;
  }

  /**
   * Builds the payload for a single customer, including their access configurations.
   * @param customer - The customer object containing customerId and other properties.
   * @returns An object representing the customer payload with name, value, and disabled status.
   */
  buildCustomerPayload(customer: XGSCustomer): UtilityOption {
    const payload = {
      name: customer.customerId,
      value: { customer, access: [] },
      disabled: false
    };

    const udlCustomer = this.udlCustomersConfig.find(customerConfig => {
      return (
        customerConfig.customer_id.toUpperCase() ===
        customer.customerId.toUpperCase()
      );
    });

    if (udlCustomer) {
      payload.value.access = udlCustomer.access;
    }

    return payload;
  }

  /**
   * Builds a list of all eXDL customers with their respective details and access configurations
   * using data from udl_customer_config.
   * @param customer - The customer object containing properties such as timezone.
   * @returns An array of UtilityOption objects representing all eXDL customers.
   */
  buildAllCustomersList(customer: XGSCustomer): UtilityOption[] {
    return this.udlCustomersConfig.map(udlCustomer => {
      return {
        name: udlCustomer.customer_id.toUpperCase(),
        value: {
          customer: {
            customerId: udlCustomer.customer_id.toUpperCase(),
            name: udlCustomer.customer_id.toUpperCase(),
            properties: {
              timezone: customer.properties?.timezone
            }
          } as XGSCustomer,
          access: udlCustomer.access
        },
        disabled: false
      };
    });
  }

  /**
   * Finalizes the customer payload by setting the selected customer and checking notifications.
   * If the customer list is empty, it initializes it with a default customer.
   * @param customerPayload - The payload containing the list of customers and the selected customer.
   * @returns The finalized customer payload.
   */
  finalizeCustomerPayload(customerPayload: UtilityCustomers): UtilityCustomers {
    if (customerPayload.customerList.length > 0) {
      if (!this.selectedCustomer) {
        customerPayload.selectedCustomer = customerPayload.customerList[0];
        this.notificationsService.checkAllNotifications(
          customerPayload.selectedCustomer.value.customer
        );
      } else {
        const customerMatch = customerPayload.customerList.find(customer => {
          return (
            customer.value.customer.customerId ===
            this.selectedCustomer.customer.customerId
          );
        });
        if (customerMatch) {
          customerPayload.selectedCustomer = customerMatch;
        } else {
          customerPayload.selectedCustomer.value = this.selectedCustomer;
        }
      }

      return customerPayload;
    }

    customerPayload.customerList = [
      {
        name: '',
        value: { customer: { customerId: '' } as XGSCustomer },
        disabled: false
      }
    ];

    return customerPayload;
  }

  /**
   * Returns customer to be selected for customer drop down
   * but will utilize customer stored in cookie if present to handle page refreshes
   * @param customerPayload contains list of XGS customers and would be selected customer
   * @returns customer to be selected from dropdown
   */
  selectCustomer(customerPayload: UtilityCustomers): UtilityOptionValue {
    if (this.cookieService.check(`${environment.environmentName}_customer`)) {
      let selectedCustomer: UtilityOptionValue;

      const cookieCustomer = this.cookieService.get(
        `${environment.environmentName}_customer`
      );
      const customerMatch = customerPayload.customerList.find(customer => {
        return (
          customer.value?.customer?.customerId?.toUpperCase() ===
          cookieCustomer?.toUpperCase()
        );
      });

      if (
        customerMatch &&
        customerPayload?.selectedCustomer?.value?.customer?.customerId !==
          this.selectedCustomer?.customer?.customerId
      ) {
        selectedCustomer = customerMatch.value;
      }

      return selectedCustomer ?? customerPayload.selectedCustomer.value;
    } else {
      return customerPayload.selectedCustomer.value;
    }
  }

  /**
   * Generates a list of unique customers using user's timezone.
   * @param userData - The user data containing roles and customers.
   * @returns - A list of unique customers with timezone information.
   */
  async tempCustomerList(userData: UDLUserData): Promise<XGSCustomer[]> {
    // NOTE: This is temporary until latency issue is resolved from XGS to get customers.
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const allRoleCustomers: string[] = Object.values(userData?.xgsRoles).flat();
    await this.setUdlCustomersConfig();
    const eXDLCustomers = this.udlCustomersConfig.map(customer => {
      return customer.customer_id.toUpperCase();
    });
    const customerSet = new Set([...allRoleCustomers, ...eXDLCustomers]);
    const deDupedCustomers: XGSCustomer[] = Array.from(customerSet).map(
      customer => {
        let name = customer;
        if (customer === 'AllCustomers') {
          name = 'All Customers';
        }
        return {
          customerId: customer,
          name,
          properties: {
            timezone: userTimezone
          }
        } as XGSCustomer;
      }
    );

    return deDupedCustomers;
  }

  /**
   * Initializes customer/utility options
   * for user and emits changes to Superset
   * @param customers list of customers
   * @param user XGS User metadata
   */
  initUserCustomers(customers: XGSCustomer[], user?: XGSUser): void {
    const customerPayload = this.buildCustomerList(customers, user);
    this.selectedCustomer = this.selectCustomer(customerPayload);
    this.changeSelectedCustomer(this.selectedCustomer);
    this.emitCustomerChanged(
      this.selectedCustomer.customer?.customerId?.toUpperCase()
    );

    this.utilityOptions.next(customerPayload.customerList);
  }

  /**
   * Finds XGS/UDL Customer
   * @param customerID customer ID
   * @returns UDL customer that matches customer ID if present
   */
  validateCustomer(customerID: string): UtilityOptionValue {
    const customer = this.utilityOptions.getValue().find(utility => {
      return utility.value.customer.customerId === customerID.toUpperCase();
    });

    return customer ? customer.value : null;
  }

  /**
   * With change of utility triggers notifications for that customer,
   * if user is in superset dialog to confirm change,
   * and emits change for superset
   * @param event Selection from customer/utility dropdown
   */
  onUtilityChanged(event: UtilityOptionValue): void {
    // If this is the current customer, do nothing
    if (
      this.selectedCustomer?.customer?.customerId &&
      event.customer.customerId.toUpperCase() ===
        this.selectedCustomer.customer.customerId.toUpperCase()
    ) {
      return;
    }

    // If the user is currently on the superset page and is in the sql_lab we
    // need to confirm the customer change. Otherwise, just change the customer.
    if (
      this.router.url === '/superset' &&
      localStorage.getItem('supersetPath')?.includes('sqllab')
    ) {
      this.confirmCustomerChange(event);
    } else {
      this.selectedCustomer = event;
      if (event.customer.customerId !== this.allCustomerOption.name) {
        this.emitCustomerChanged(event.customer.customerId.toUpperCase());
        this.notificationsService.checkAllNotifications(event.customer);
      }
      this.changeSelectedCustomer(event);
    }
  }

  /**
   * Provides dialog for user to confirm customer
   * change to not loose work/place in Superset
   * @param customer customer/utility to select
   */
  confirmCustomerChange(customer: UtilityOptionValue): void {
    const prevCustomer = this.selectedCustomer;
    this.selectedCustomer = customer;

    // Open the confirmation dialog
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: {
        title: 'Are you sure?',
        // eslint-disable-next-line max-len
        description:
          'Changing customers requires refreshing Superset. If you have any unsaved queries, please save them before continuing.',
        showCancelButton: true
      }
    });

    // If the user confirms, emit the customer changed event
    // Otherwise, revert the customer selection and update the UI
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.emitCustomerChanged(
          this.selectedCustomer.customer.customerId.toUpperCase()
        );
        this.changeSelectedCustomer(customer);
        this.notificationsService.checkAllNotifications(
          this.selectedCustomer.customer
        );
      } else {
        this.selectedCustomer = prevCustomer;
      }
    });
  }

  /**
   * Sets customer cookie, removes previous customer
   * superset token, emit customer change for superset
   * @param customer new customer ID selected
   */
  async emitCustomerChanged(customer: string): Promise<void> {
    if (customer === this.allCustomerOption.name.toUpperCase()) {
      return;
    }

    // Set customer cookie for superset to use
    this.cookieService.set(
      `${environment.environmentName}_customer`,
      customer,
      null,
      '/',
      this.supersetService.domainHelper(),
      true,
      'None'
    );
    localStorage.setItem(CustomerSession.CUSTOMER, customer);

    // Remove the superset-auth token to update customer change
    this.supersetService.invalidateToken();

    if (customer && customer !== '') {
      await this.supersetService.checkSupersetAuth();
    }

    // Dispatch customer changed window event
    const custChangeEvent = new Event('customerChanged');
    window.dispatchEvent(custChangeEvent);
  }

  /**
   * Sets the UDL customers configuration if it is not already set.
   */
  async setUdlCustomersConfig(): Promise<void> {
    try {
      if (this.udlCustomersConfig.length === 0) {
        const response = await this.getCustomerConfig(['EMPTY'], true);
        this.udlCustomersConfig = response.data as CustomerConfig[];
        this.udlCustomersConfig.sort((x, y) => {
          return x.customer_id.localeCompare(y.customer_id);
        });
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error getting XDL customers config'
      });
    }
  }

  /**
   * Retrieves the first customer with access to a specific feature and is not disabled.
   * @param feature - The feature to check access for.
   * @returns - The customer with access to the feature, or undefined if none found.
   */
  getCustomerWithAccess(feature: string): UtilityOption {
    return this.utilityOptions.getValue().find(customer => {
      return customer.value.access?.includes(feature) && !customer.disabled;
    });
  }

  /**
   * Verifies if the selected customer has access to a specific feature.
   * @param feature - The feature to check access for.
   * @returns - True if the customer has access, false otherwise.
   */
  verifyCustomerAccess(feature: string): boolean {
    return this.selectedCustomer.access?.includes(feature);
  }

  /**
   * Disables customers who do not have access to a specific feature.
   * @param feature - The feature to check access for.
   */
  disableCustomersWithNoAccess(feature: string): void {
    this.utilityOptions.getValue().forEach(utilityOption => {
      if (!utilityOption.value.access?.includes(feature)) {
        utilityOption.disabled = true;
      }
    });
  }

  /**
   * Disables customers in dropdown due to user not having permission for that customer.
   * @param customers list of customer IDs user has permission for
   */
  disableCustomersWithoutPermissions(customers: string[]): void {
    this.utilityOptions.getValue().forEach(utilityOption => {
      utilityOption.disabled = !customers.includes(
        utilityOption.value.customer.customerId
      );
    });
  }

  /**
   * Enables access for all customers by setting the
   * `disabled` property to `false` for each utility option.
   */
  enableCustomersAccess(): void {
    this.utilityOptions.getValue().forEach(utilityOption => {
      utilityOption.disabled = false;
    });
  }

  /**
   * Gets customer configuration
   * @param customerId (optional) customer ID - if not provided, uses globally selected customer (UDL Customer Dropdown)
   * @param userCustomers (optional) flag to get user's customers
   * @returns customer configuration
   */
  async getCustomerConfig(
    customerId?: string[],
    userCustomers?: boolean
  ): Promise<ApiResponse> {
    const queryParams: { customer_id: string[]; user?: boolean } = {
      customer_id: customerId || [this.selectedCustomer.customer.customerId]
    };

    if (userCustomers) {
      queryParams.user = userCustomers;
    }

    return this.amplifyService.callAPI<ApiResponse>(
      'get',
      'customer-onboard',
      '/get_customer_info',
      {
        queryParams
      }
    );
  }

  /**
   * Lifecycle hook that is called when the service is destroyed.
   * Unsubscribes from the storage event listener if it exists.
   */
  ngOnDestroy(): void {
    if (this.storageSubscription) {
      this.storageSubscription.unsubscribe();
    }
  }

  /**
   * Listens for cross-tab customer changes via the 'storage' event.
   * Subscribes to storage events and handles them using handleStorageEvent.
   */
  private crossTabCustomerChange(): void {
    this.storageSubscription = fromEvent<StorageEvent>(
      window,
      'storage'
    ).subscribe(event => {
      return this.handleStorageEvent(event);
    });
  }

  /**
   * Handles the 'storage' event to detect cross-tab customer changes.
   * If the customer has changed, updates the utility, shows an alert, and handles route changes.
   * @param {StorageEvent} event - The storage event containing the key and new value.
   */
  private handleStorageEvent(event: StorageEvent): void {
    const customer = this.selectedCustomer?.customer?.customerId?.toUpperCase();
    if (event.key === CustomerSession.CUSTOMER && event.newValue !== customer) {
      this.onUtilityChanged(this.validateCustomer(event.newValue));
      this.showCustomerChangeAlert(event.newValue, CustomerSession.TAB_CHANGE);
      this.handleRouteChange();

      // Ensures any existing timeouts are cleared before setting a new one
      this.customerDebounceTimeout = setTimeout(() => {
        this.customerDebounceTimeout = null;
      }, 1000);

      return;
    }

    // This to allow for customer change alert for additional sessions to be shown from tab doing the change
    if (
      !this.customerDebounceTimeout &&
      event.key === CustomerSession.CUSTOMER_UPDATE &&
      event.newValue
    ) {
      localStorage.removeItem(CustomerSession.CUSTOMER_UPDATE);
      this.showCustomerChangeAlert(
        this.selectedCustomer?.customer?.customerId?.toUpperCase()
      );

      return;
    }
  }

  /**
   * Displays an alert indicating that the customer has changed.
   * @param {string} newCustomer - The new customer ID that has been selected.
   * @param changeReason - type of customer update
   */
  private showCustomerChangeAlert(
    newCustomer: string,
    changeReason?: string
  ): void {
    this.alertsService.addAlert({
      type: ColorType.warn,
      title: 'Customer Changed',
      message: `Customer changed to reflect customer selected in additional session(s): ${newCustomer}`,
      dismissable: true
    });

    // This to allow for customer change alert for additional sessions to be shown from tab doing the change
    if (changeReason) {
      localStorage.setItem(CustomerSession.CUSTOMER_UPDATE, changeReason);
    }
  }

  /**
   * Handles route changes when the customer changes.
   * If the current route includes '/scheduler', sets the state and navigates to '/scheduler'.
   */
  private handleRouteChange(): void {
    const currentRoute = this.router.url;
    if (currentRoute.includes('/scheduler')) {
      this.stateService.setState({
        '/scheduler': { crossTabChange: true }
      });
      this.router.navigate(['/scheduler']);
    } else if (currentRoute.includes('/data-import')) {
      this.customerChangeAccessRoute(FeatureFlag.DATA_IMPORT);
      this.stateService.setState({
        '/data-import': { crossTabChange: true }
      });
      this.router.navigate(['/data-import']);
    }
  }

  /**
   * Checks if the customer has access to a specific feature and navigates to the home page if not.
   * @param feature - The feature to check access for.
   */
  private customerChangeAccessRoute(feature: string): void {
    if (!this.verifyCustomerAccess(feature)) {
      this.router.navigate(['/']);
    }
  }
}
