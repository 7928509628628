import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { AmplifyService } from '../aws/amplify/amplify.service';
import { LoadingService } from '@gravity-angular/layout';
import { DatadogService } from '../shared/services/datadog-services/datadog.service';
import { AlertsService } from '@gravity-angular/base';
import { ColorType } from '@gravity-angular/models';

/**
 * Component to display the user menu dialog
 */
@Component({
  selector: 'app-user-menu-dialog',
  templateUrl: './user-menu-dialog.component.html',
  styleUrls: ['./user-menu-dialog.component.scss']
})
export class UserMenuDialogComponent {
  disableDownload = false;
  downloadText = $localize`:@@driver-download-dialog_driver-download-dialog_download:Download`;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserMenuDialogComponent>,
    private readonly amplifyService: AmplifyService,
    private readonly loadingService: LoadingService,
    private readonly datadogService: DatadogService,
    private readonly alertsService: AlertsService
  ) {}

  /**
   * Close the dialog
   */
  closeDialog(): void {
    this.dialogRef.close();
  }

  /**
   * Download the driver
   * @param version The version of the driver to download
   */
  async getDriver(version: string): Promise<void> {
    this.disableDownload = true;
    this.loadingService.showLoading(true);
    try {
      const s3Data = await this.amplifyService.getFileFromS3(
        `${version}.zip`,
        true,
        environment.odbcBucket
      );

      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(await s3Data.body);
      a.href = objectUrl;
      a.download = `${version}.zip`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      this.alertsService.addAlert({
        type: ColorType.warn,
        title: 'Error downloading ODBC driver',
        message:
          'Error occurred downloading AWS ODBC driver. If problem persists, please contact your admin.',
        dismissable: true
      });
      this.datadogService.errorTracking(error, {
        message: 'Error occurred downloading AWS ODBC driver...'
      });
    }

    this.loadingService.showLoading(false);
    this.disableDownload = false;
    this.closeDialog();
  }
}
