import { Injectable } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NavigationExtras, Router } from '@angular/router';
import {
  ExdlPermissions,
  ExdlRoles,
  UDLUserData,
  XGSCustomer,
  XGSRole,
  XGSUser,
  XGSUserRoles
} from '@common/models/user-management.model';
import {
  RolePreference,
  XGSUserPreference
} from '@common/models/user-preference.model';
import { environment } from '@env/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AmplifyService } from '../../../aws/amplify/amplify.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { PermissionPerRoleModel } from '../create-user/user-permissions';
import { DatadogService } from '../../../shared/services/datadog-services/datadog.service';

/**
 * Service to handle user functions such as user preferences, page size, navigation, validate users roles, etc.
 */
@Injectable({
  providedIn: 'root'
})
export class UserFunctionsService {
  userPreferences = {} as XGSUserPreference;
  userPageSize = new BehaviorSubject(null);

  rolePreferences = {} as RolePreference;
  rolePageSize = new BehaviorSubject(null);
  roleDetailPageSize = new BehaviorSubject(null);

  constructor(
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService,
    private readonly amplifyService: AmplifyService
  ) {}

  /**
   * Retrieves user or role preferences from localStorage
   * @param path preference key (user or role)
   * @returns user or role preferences from localStorage
   */
  getPreferences(path: string): XGSUserPreference | RolePreference {
    const preference = localStorage.getItem(path);
    if (preference) {
      return JSON.parse(preference);
    }
  }

  /**
   * Updates user preference in localStorage
   * @param key preference key
   * @param value preference value
   */
  updateUserPreferences(key: string, value: any): void {
    this.userPreferences[key] = value;
    localStorage.setItem('user', JSON.stringify(this.userPreferences));
  }

  /**
   * Updates role preference in localStorage
   * @param key preference key
   * @param value preference value
   */
  updateRolePreferences(key: string, value: any): void {
    this.rolePreferences[key] = value;
    localStorage.setItem('role', JSON.stringify(this.rolePreferences));
  }

  /**
   * Saves value used for page size of
   * user list to persist across session to be subscribed to
   * @param event pagination event captured in mat-table
   */
  updatedUserPageSize(event: PageEvent) {
    this.userPageSize.next(event);
  }

  /**
   * Saves value used for page size of
   * role list to persist across session to be subscribed to
   * @param event pagination event captured in mat-table
   */
  updatedRolePageSize(event: PageEvent) {
    this.rolePageSize.next(event);
  }

  /**
   * Saves value used for page size of
   * user list of role detail screen to persist across
   * session to be subscribed to
   * @param event pagination event captured in mat-table
   */
  updatedRoleDetailPageSize(event: PageEvent) {
    this.roleDetailPageSize.next(event);
  }

  /**
   * Helper function to set page size and page index of mat-tables via their mat-paginator
   * @param paginators list of MatPaginator to set page size
   * @param size size to set page to of mat-table
   * @param pageEvent mat-paginator event
   * @param path either user or role path
   */
  setScreenPaginators(
    paginators: MatPaginator[],
    pageEvent: PageEvent,
    path: string = null
  ): void {
    for (const paginator of paginators) {
      const preferences = this.getPreferences(path);
      if (paginator && paginator.pageSize !== pageEvent.pageSize) {
        paginator.pageSize = pageEvent.pageSize;
        paginator.pageIndex = preferences?.pageIndex
          ? preferences.pageIndex
          : paginator.pageIndex;
        paginator.page.emit({
          length: paginator.length,
          pageIndex: paginator.pageIndex,
          pageSize: paginator.pageSize
        });
      }
    }
  }

  /**
   * Navigates to the user detail view or user list view based on the last route.
   * @param user - The user whose details are to be viewed.
   * @param lastRoute - The last route visited. Defaults to null.
   */
  navigateToUserDetailView(user: XGSUser, lastRoute: string = null): void {
    if (lastRoute === '/user/user-list') {
      this.navigateToUserListView();

      return;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: user.id
      }
    };
    this.router.navigate(['user/user-detail'], navigationExtras);
  }

  /**
   * Navigates to User List Screen
   */
  navigateToUserListView(): void {
    this.router.navigate(['user/user-list']);
  }

  /**
   * Checks if user is the same as the current user
   * @param user1 Current user
   * @param user2 User to compare
   * @returns true if both users are the same
   */
  sameUserCheck(user1: UDLUserData, user2: XGSUser): boolean {
    return user1?.id === user2?.id;
  }

  /**
   * Checks if user has permission
   * @param usersRoles Roles of the user
   * @param permission Permission to check
   * @returns true if user has permission
   */
  permissionCheck(usersRoles: XGSRole[], permission: string): boolean {
    return usersRoles.some(role => {
      return role.permissions.includes(permission) || role.name === permission;
    });
  }

  /**
   * Returns list of customers user has a specific permission for
   * @param userData - user data to check against
   * @param permission - permission to look for
   * @returns list of customers user has permission associated with
   */
  customersWithPermission(userData: UDLUserData, permission: string): string[] {
    let customers = [];
    const rolesWithAccess =
      userData.xgsPermissions?.filter(role => {
        return (
          role.permissions.includes(permission) || role.name === permission
        );
      }) ?? [];

    rolesWithAccess.forEach(role => {
      const roleCustomers = userData.xgsRoles?.[role.name];
      if (Array.isArray(roleCustomers)) {
        customers = [...customers, ...roleCustomers];
      }
    });

    return customers;
  }

  /**
   * Checks if user has Bi Access
   * @param user User to check
   * @returns true if user has permission
   */
  biAccessCheck(user: XGSUser): boolean {
    return Object.prototype.hasOwnProperty.call(
      user.roles[environment.xgsClientID],
      'BiAccess'
    );
  }

  /**
   * Checks if user has specific role
   * @param role - role to check for
   * @param userRoles - roles to check against
   * @returns true if user has role
   */
  userHasRole(role: ExdlRoles, userRoles: XGSUserRoles): boolean {
    return Object.prototype.hasOwnProperty.call(userRoles, role);
  }

  /**
   * Checks if user has Xylem role
   * @param roles
   * @returns true if user has Xylem role
   */
  xylemRoleCheck(roles: XGSUserRoles): boolean {
    return (
      this.userHasRole(ExdlRoles.SUPER_ADMIN, roles) ||
      this.userHasRole(ExdlRoles.PROFESSIONAL_SERVICES, roles) ||
      this.userHasRole(ExdlRoles.TECH_SERVICES, roles)
    );
  }

  /**
   * Checks if user has local admin or distributor role
   * @param roles
   * @returns true if user has local admin or distributor role
   */
  localAdminOrDistributorCheck(roles: XGSUserRoles): boolean {
    return (
      this.userHasRole(ExdlRoles.LOCAL_ADMIN, roles) ||
      this.userHasRole(ExdlRoles.DISTRIBUTOR, roles)
    );
  }

  /**
   * Tracks user activity such as new user creation, role changes, and login events.
   * @param userInfo - The information of the user to track.
   * @param newUser - Indicates if the user is new.
   * @param roleTrack - Indicates if role changes should be tracked.
   * @param loginTrack - Indicates if login events should be tracked.
   * @returns A promise that resolves to the API response or undefined if an error occurs.
   */
  async trackUser(
    userInfo: Object,
    newUser: boolean,
    roleTrack: boolean,
    loginTrack: boolean
  ): Promise<Object> {
    const postBody = {
      body: {
        userInfo,
        newUser,
        roleTrack,
        loginTrack,
        token: await lastValueFrom(this.oidcSecurityService.getAccessToken())
      }
    };
    try {
      const apiResponse = await this.amplifyService.callAPI(
        'post',
        'user-tracking',
        '/log-activity',
        postBody
      );

      return apiResponse;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error logging user activity'
      });

      return;
    }
  }

  /**
   * Removes the login activity of a user.
   * @param userInfo - The user information containing the user ID.
   * @returns A promise that resolves to the API response or undefined if an error occurs.
   */
  async removeUserLogin(userInfo: XGSUser): Promise<Object> {
    const params = {
      body: {
        user: userInfo.id,
        xgs: await lastValueFrom(this.oidcSecurityService.getAccessToken())
      }
    };

    try {
      const apiResponse = await this.amplifyService.callAPI(
        'post',
        'user-tracking',
        '/delete-activity',
        params
      );

      return apiResponse;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error deleting user activity'
      });

      return;
    }
  }

  /**
   * Retrieves user tracking information.
   * @param accessToken - The access token for authentication.
   * @returns A promise that resolves to the user tracking information.
   */
  async getUserTracking(accessToken: string): Promise<Object> {
    try {
      const params = {
        headers: {
          xgs: accessToken
        }
      };

      const apiResponse = await this.amplifyService.callAPI(
        'get',
        'user-tracking',
        '/get-activity',
        params
      );

      return apiResponse;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error retrieving last login information'
      });

      return {};
    }
  }

  /**
   * Sets the customer permission based on the given role and superset permissions.
   * @param permission - The permission to set.
   * @param udlRole - The form control containing the role and superset permissions.
   * @returns True if the permission is set, otherwise false.
   */
  setCustomerPermission(
    permission: PermissionPerRoleModel,
    udlRole: AbstractControl
  ): boolean {
    const role = udlRole.get('role')?.value as XGSRole;
    const supersetPermissions = udlRole.get('supersetPermissions')
      .value as XGSRole[];
    if (
      supersetPermissions?.some(superRole => {
        return superRole.name === permission.value;
      })
    ) {
      return true;
    }

    return role?.permissions.includes(permission.value) || false;
  }

  /**
   * Creates a role list that can be assigned based on the selected customer,
   * the roles the user has, and the customer associated with their corresponding role.
   * @param customer - The customer to check the role against.
   * @param role - The role to set.
   * @param userData - The user data containing roles and permissions.
   * @param selectedRoles - The list of selected roles.
   * @returns True if the role can be set, otherwise false.
   */
  setRoleList(
    customer: XGSCustomer,
    role: XGSRole,
    userData: UDLUserData,
    selectedRoles: XGSRole[]
  ): boolean {
    if (role.name === 'BiAccess' || role.name.includes('Superset')) {
      return false;
    }
    if (customer.name !== 'All Customers') {
      const customerRoleCheck = role.customerId === customer.customerId;
      if (
        customerRoleCheck &&
        this.primaryRoleforCustomer(customer.customerId, userData) !==
          ExdlRoles.XYLEM_CLIENT_SUCCESS
      ) {
        return true;
      }

      return this.externalCustomerRole(customer, role, userData, selectedRoles);
    }
    if (customer.name === 'All Customers') {
      if (this.xylemRoleCheck(userData.xgsRoles)) {
        if (
          role.name === ExdlRoles.PROFESSIONAL_SERVICES ||
          role.name === ExdlRoles.TECH_SERVICES ||
          role.name === ExdlRoles.XYLEM_CLIENT_SUCCESS
        ) {
          return true;
        }
      }
      if (this.userHasRole(ExdlRoles.SUPER_ADMIN, userData.xgsRoles)) {
        if (role.name === ExdlRoles.SUPER_ADMIN) {
          return true;
        }
      }
      if (
        role.name === ExdlRoles.XYLEM_CLIENT_SUCCESS &&
        this.userHasRole(ExdlRoles.XYLEM_CLIENT_SUCCESS, userData.xgsRoles)
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Determines if a role can be assigned to an external customer.
   * @param customer - The customer to check the role against.
   * @param role - The role to verify.
   * @param userData - The user data containing roles and permissions.
   * @param selectedRoles - The list of selected roles.
   * @returns True if the role can be assigned to the customer, otherwise false.
   */
  externalCustomerRole(
    customer: XGSCustomer,
    role: XGSRole,
    userData: UDLUserData,
    selectedRoles: XGSRole[]
  ): boolean {
    const xylemUser = this.xylemRoleCheck(userData.xgsRoles);
    const xylemClientSuccessCheck =
      this.sameRolesVerification(
        ExdlRoles.XYLEM_CLIENT_SUCCESS,
        selectedRoles
      ) || selectedRoles.length === 1;

    if (role.name === ExdlRoles.XYLEM_CLIENT_SUCCESS) {
      return (
        this.verifyRoletoCustomer(
          ExdlRoles.XYLEM_CLIENT_SUCCESS,
          customer,
          xylemUser,
          userData
        ) && xylemClientSuccessCheck
      );
    }

    if (xylemClientSuccessCheck && selectedRoles.length > 1) {
      return false;
    }

    if (role.name === ExdlRoles.DISTRIBUTOR) {
      return xylemUser;
    } else if (role.name === ExdlRoles.LOCAL_ADMIN) {
      return this.verifyRoletoCustomer(
        ExdlRoles.DISTRIBUTOR,
        customer,
        xylemUser,
        userData
      );
    } else if (
      role.name === ExdlRoles.POWER_USER ||
      role.name === ExdlRoles.READ_ONLY
    ) {
      if (
        this.verifyRoletoCustomer(
          ExdlRoles.DISTRIBUTOR,
          customer,
          xylemUser,
          userData
        )
      ) {
        return true;
      } else if (
        this.verifyRoletoCustomer(
          ExdlRoles.LOCAL_ADMIN,
          customer,
          xylemUser,
          userData
        )
      ) {
        return true;
      } else {
        const createUserCheck = userData.xgsPermissions.filter(
          rolePermission => {
            return rolePermission.permissions.includes(
              ExdlPermissions.CREATE_USERS
            );
          }
        );

        return createUserCheck.some(rolePermission => {
          return rolePermission.customerId === customer.customerId;
        });
      }
    }

    return false;
  }

  /**
   * Verifies if all selected roles are the same as the given role name or are non-primary roles.
   * @param roleName - The role name to verify against.
   * @param selectedRoles - The list of selected roles.
   * @returns True if all selected roles match the role name or are non-primary roles, otherwise false.
   */
  sameRolesVerification(roleName: string, selectedRoles: XGSRole[]): boolean {
    return selectedRoles.every(assignedRole => {
      return (
        assignedRole?.name === roleName ||
        !assignedRole ||
        assignedRole?.name.includes('Superset')
      );
    });
  }

  /**
   * Verifies if a role is assigned to a customer.
   * @param verifyRole - The role to verify.
   * @param customer - The customer to check the role against.
   * @param xylemUser - Indicates if the user is a Xylem user.
   * @param userData - The user data containing roles.
   * @returns True if the role is assigned to the customer, otherwise false.
   */
  verifyRoletoCustomer(
    verifyRole: string,
    customer: XGSCustomer,
    xylemUser: boolean,
    userData: UDLUserData
  ): boolean {
    if (xylemUser) {
      return true;
    } else if (userData.xgsRoles[verifyRole]) {
      return (
        userData.xgsRoles[verifyRole].includes(customer.customerId) ||
        userData.xgsRoles[verifyRole].includes('AllCustomers')
      );
    }

    return false;
  }

  /**
   * Retrieves the primary role for a given customer.
   * @param customerId - The ID of the customer.
   * @param userData - The user data containing roles.
   * @returns The primary role for the customer, or undefined if not found.
   */
  primaryRoleforCustomer(customerId: string, userData: UDLUserData): string {
    const customerID = customerId.toUpperCase();

    return Object.keys(userData.xgsRoles).find(role => {
      return (
        (userData.xgsRoles[role].includes(customerID) ||
          userData.xgsRoles[role].includes('AllCustomers')) &&
        this.filterPermissionBasedRoles(role)
      );
    });
  }

  /**
   * Filters out roles that are considered non-primary.
   * @param role - The role to check.
   * @returns True if the role is primary, otherwise false.
   */
  filterPermissionBasedRoles(role: string): boolean {
    const nonPrimaryRoles = [
      'Superset',
      'OXI:',
      'BiAccess',
      'AppstoreAccess',
      'ClusterAnalysis',
      'AnomalyDetection',
      'BusinessRuleEngine'
    ];

    return !nonPrimaryRoles.some(nonPrimaryRole => {
      return role.includes(nonPrimaryRole);
    });
  }

  /**
   * Validates if the edit user option is allowed based on the roles, customers, and permissions.
   * @param editUser - The user being edited.
   * @param userEditing - The user performing the edit.
   * @returns True if the edit user option is valid, otherwise false.
   */
  validateEditUserOption(editUser: XGSUser, userEditing: UDLUserData): boolean {
    const editUserRoles = editUser.roles[environment.xgsClientID];

    if (
      this.userHasRole(ExdlRoles.XYLEM_CLIENT_SUCCESS, userEditing.xgsRoles)
    ) {
      return this.userHasRole(ExdlRoles.XYLEM_CLIENT_SUCCESS, editUserRoles);
    }

    const customers = this.customersWithPermission(
      userEditing,
      ExdlPermissions.EDIT_USERS
    );

    return (
      customers.includes('AllCustomers') ||
      Object.values(editUserRoles)
        .flat()
        .some(customer => {
          return customers.includes(customer);
        })
    );
  }

  /**
   * Creates an object for customer/role display for permission.
   * @param role - The role object containing role details.
   * @param userDetail - The user details object containing roles.
   * @param roleName - The name of the role to find in user details.
   * @returns An object with customer list and role name for display.
   */
  currUserRoleButton(
    role: XGSRole,
    userDetail: XGSUser,
    roleName: string
  ): object {
    const userRolesObject = userDetail.roles[environment.xgsClientID];
    for (const [key, value] of Object.entries(userRolesObject)) {
      let temp = [];
      temp = temp.concat(value);
      if (key === roleName && temp.length > 0) {
        if (temp.length > 2) {
          let endList = temp.splice(0, 2).join(', ');
          endList = `${endList}...`;

          return { customer: endList, role: role.name };
        }
        const endList = temp.join(', ');

        return { customer: endList, role: role.name };
      }
    }
  }

  /**
   * Checks if the user has any of the required roles.
   * @param roles - The roles assigned to the user.
   * @param requiredRoles - The list of required roles to check against.
   * @returns True if the user has any of the required roles, otherwise false.
   */
  hasRequiredRole(roles: XGSUserRoles, requiredRoles: ExdlRoles[]): boolean {
    return Object.keys(roles).some(role => {
      return requiredRoles.includes(role as ExdlRoles);
    });
  }

  /**
   * Verifies if the email is valid for Xylem client success role.
   * @param rolesForm - The form array containing user roles.
   * @param email - The email address to verify.
   * @returns True if the email is valid for Xylem client success, otherwise false.
   */
  verifyEmailClientSuccess(rolesForm: FormArray, email: string): boolean {
    const clientSuccess = rolesForm.controls.some(role => {
      return role.get('role').value.name === ExdlRoles.XYLEM_CLIENT_SUCCESS;
    });

    if (clientSuccess) {
      const xylemEmailRegex = /^[a-zA-Z0-9._%+-]+@xylem\.com$/;

      return xylemEmailRegex.test(email);
    }

    return true;
  }
}
