import { Injectable } from '@angular/core';
import { DatadogService } from '../datadog-services/datadog.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '@env/environment';

/**
 * Custom translator service to handle localization and translation of text.
 */
@Injectable({
  providedIn: 'root'
})
export class CustomTranslatorService {
  key = 'udl-localization/processed';
  userLocale: string;
  userLocaleData: Localization;
  localeSubject = new BehaviorSubject<Localization>({
    locale: 'en_US',
    timestamp: new Date(),
    translations: {}
  });

  constructor(
    private readonly datadogService: DatadogService,
    private readonly http: HttpClient
  ) {}

  /**
   * Fetches and stores localization file for given locale.
   * If locale is current or fetch fails, reverts to default.
   * @param locale - Desired locale.
   * @param force - Forces a fetch from S3 even if locale data is less than the expiration time.
   * @param version - Version of the localization file (not used).
   */
  async getLocalizationFile(locale: string, force = false): Promise<void> {
    await this.getDefaultLocale();

    try {
      // If the correct locale is already set, return
      if (this.userLocale === locale) {
        return;
      }

      // If locale data is saved in local storage, not expired, and force is not true, return
      const localeData = this.loadFromLocalStorage(locale);
      if (localeData && !force) {
        this.userLocaleData = localeData;
        this.localeSubject.next(this.userLocaleData);
        this.userLocale = locale;

        return;
      }

      this.userLocaleData = (await lastValueFrom(
        this.http.get(`assets/i18n/messages-${locale}.json`)
      )) as Localization;
      this.userLocaleData.timestamp = new Date();
      this.userLocale = locale;
      this.localeSubject.next(this.userLocaleData);

      // Save locale data to local storage for future use
      localStorage.setItem('localeData', JSON.stringify(this.userLocaleData));
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Error occurred while fetching locale data for ${locale}`
      });
      await this.getDefaultLocale();
    }
  }

  /**
   * Loads and stores default locale data if userLocale is not set.
   */
  async getDefaultLocale(): Promise<void> {
    if (!this.userLocale) {
      try {
        this.userLocaleData = (await lastValueFrom(
          this.http.get('assets/i18n/messages-en_US.json')
        )) as Localization;
        this.userLocale = 'en_US';
        this.localeSubject.next(this.userLocaleData);
      } catch (error) {
        this.datadogService.errorTracking(error, {
          message: 'Failed to get default locale data'
        });
      }
    }
  }

  /**
   * Allows for translation of a given key
   * @param localizeValue string template (not used)
   * @param key i18n custom Identifier
   * @returns value of key in userLocaleData
   */
  translate(localizeValue: string, key: string): string {
    try {
      const value = this.userLocaleData?.translations?.[key];

      return value;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Failed to translate ${key}`
      });

      return 'Failed localization of text';
    }
  }

  /**
   * Loads locale data from local storage if it exists and is not expired.
   * @param locale - Desired locale.
   * @returns locale data if it exists and is not expired.
   */
  loadFromLocalStorage(locale: string): Localization {
    const localStorageData = localStorage.getItem('localeData');

    // If no locale data is saved, return
    if (!localStorageData) {
      return;
    }

    const localeData = JSON.parse(localStorageData);

    // If locale data is for a different locale, return
    if (localeData.locale !== locale) {
      return;
    }

    // Check age of locale data
    const currentTime = new Date().getTime();
    const localTime = new Date(localeData.timestamp || '1970-01-01').getTime();
    const localeAge = (currentTime - localTime) / 1000;

    // If locale data is older than the expiration time, return
    if (localeAge > environment.localeExpirationSeconds) {
      return;
    }

    // Otherwise, return the locale data
    return localeData;
  }
}

export interface Localization {
  locale: string;
  timestamp: Date;
  translations: Record<string, string>;
}

/**
 * Custom paginator service to allow for translation of paginator labels.
 */
import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 *
 */
@Injectable({
  providedIn: 'root'
})
export class PaginatorService extends MatPaginatorIntl {
  constructor(
    private readonly customTranslatorService: CustomTranslatorService
  ) {
    super();
    this.customTranslatorService.localeSubject.subscribe(() => {
      this.itemsPerPageLabel = this.customTranslatorService.translate(
        $localize`:@@itemsPerPage:Items per page`,
        'itemsPerPage'
      );
      this.nextPageLabel = this.customTranslatorService.translate(
        $localize`:@@paginatorNextPage:Next Page`,
        'paginatorNextPage'
      );
      this.previousPageLabel = this.customTranslatorService.translate(
        $localize`:@@paginatorPreviousPage:Previous Page`,
        'paginatorPreviousPage'
      );
      this.firstPageLabel = this.customTranslatorService.translate(
        $localize`:@@paginatorFirstPage:First Page`,
        'paginatorFirstPage'
      );
      this.lastPageLabel = this.customTranslatorService.translate(
        $localize`:@@paginatorLastPage:Last Page`,
        'paginatorLastPage'
      );
    });
  }
}
