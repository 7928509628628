import { Pipe, PipeTransform } from '@angular/core';
import { Export, ScheduleExecution } from '@common/models/scheduler-models';
import { FilterType, TimeFilterLabel } from '@common/models/superset.model';
import { XGSCustomer } from '@common/models/user-management.model';
import moment from 'moment-timezone';
import { CustomTranslatorService } from '../services/custom-translator/custom-translator.service';
import { DatadogService } from '../services/datadog-services/datadog.service';

/**
 *
 */
@Pipe({
  name: 'timezoneConversion'
})
export class TimezoneConversionPipe implements PipeTransform {
  constructor(
    private readonly customTranslatorService: CustomTranslatorService,
    private readonly datadogService: DatadogService
  ) {}

  /**
   * Will format time based on XGS Customer timezone if present
   * @param time UTC time string
   * @param customer XGS Customer
   * @param quotes if string is surrounded by quotes
   * @returns UTC time if no timezone available otherwise converts
   */
  transform(time: string, customer: XGSCustomer, quotes?: boolean): string {
    try {
      let temp = time;
      if (!temp) {
        return;
      } else if (quotes) {
        temp = this.formatTime(time);
      }
      temp = new Date(temp).toISOString();

      moment.locale(this.customTranslatorService.userLocale);

      let momentTime = moment(temp);
      if (customer.properties?.timezone) {
        momentTime = momentTime.tz(customer.properties.timezone);
      }

      return momentTime.format('L - LTS');
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error converting timezone pipe'
      });

      return;
    }
  }

  /**
   * Formats time for GUI
   * @param time time to format
   * @returns time without quotes
   */
  formatTime(time: string): string {
    return time.replace(/['"]+/g, '');
  }
}

/**
 *
 */
@Pipe({
  name: 'abbrevateZone'
})
export class AbbrevateTimeZonePipe implements PipeTransform {
  /**
   *
   * @param customer
   */
  transform(customer: XGSCustomer): string {
    if (customer?.properties?.timezone) {
      return moment.tz(customer.properties.timezone).format('z');
    }

    return 'UTC';
  }
}

/**
 *
 */
@Pipe({
  name: 'userAudit'
})
export class UserAuditImagePipe implements PipeTransform {
  /**
   * Formats inital and altered value of user audit trail for GUI
   * @param auditValue inital or altered value from audit trail
   * @returns formatted string for GUI
   */
  transform(auditValue: string): string {
    const jsonValue = JSON.parse(auditValue);
    const entry = Object.entries(jsonValue);
    let temp = '';
    for (const [key, value] of entry) {
      if (entry.length > 3 && key === 'roles') {
        continue;
      } else if (key === 'roles') {
        for (const [key2, value2] of Object.entries(value)) {
          temp = `${temp}${key2}\n`;
        }
      } else {
        temp = `${temp}${key}: ${value}\n`;
      }
    }
    if (temp.length === 0) {
      temp = 'No Value';
    }

    return temp;
  }
}

/**
 *
 */
@Pipe({
  name: 'roleAudit'
})
export class RoleAuditImagePipe implements PipeTransform {
  /**
   * Formats inital and altered value of role audit trail for GUI
   * @param auditValue inital or altered value from audit trail
   * @returns formatted string for GUI
   */
  transform(auditValue: string): string {
    const jsonValue = JSON.parse(auditValue);
    const entry = Object.entries(jsonValue);
    let temp = '';
    for (const [key, value] of entry) {
      if (key === 'id') {
        continue;
      } else {
        temp = `${temp}${key}: ${value}\n`;
      }
    }
    if (temp.length === 0) {
      temp = 'No Value';
    }

    return temp;
  }
}

/**
 *
 */
@Pipe({
  name: 'roleName'
})
export class RoleNamePipe implements PipeTransform {
  // Due to lack of customer scoping of roles
  // role names are formated with Customer Id and need
  // to be removed before loading to HTML
  // ***Potential can be removed as no custom roles have been created and scoping has been added needs validation before removing*** //
  /**
   *
   * @param role
   */
  transform(role: string): string {
    const name = role.substring(0, role.indexOf(' ->'));
    if (name.length > 0) {
      return name;
    }

    return role;
  }
}

/**
 *
 */
@Pipe({
  name: 'scheduleImage'
})
export class ScheduleImagePipe implements PipeTransform {
  /**
   * Formats both old and new image for UI
   * @param image image to be formated for UI
   */
  transform(image: string): string {
    let formatedImage = '';
    try {
      const jsonImg = JSON.parse(image);
      for (const field of Object.keys(jsonImg)) {
        if (field === 'conditional_statement') {
          formatedImage = this.formatConditional(formatedImage, jsonImg, field);
        } else if (field === 'reports') {
          formatedImage = this.formatReports(formatedImage, jsonImg, field);
        } else if (field === 'Failed') {
          formatedImage = `${jsonImg[field]}\n`;
        } else {
          formatedImage = `${formatedImage}${field}: ${this.formatText(
            jsonImg[field]
          )}\n`;
        }
      }
    } catch (error) {
      formatedImage = '';
    }

    return formatedImage ? formatedImage : 'Empty or No Change';
  }

  /**
   *
   * @param text
   */
  formatText(text: string): string {
    try {
      return text.replace(/,/g, ', ');
    } catch (error) {
      return text;
    }
  }

  /**
   * Pipe helper function to format conditional statements
   * @param formatedImage current formatted image
   * @param jsonImg JSON version of original image
   * @param key key from jsonImg Object
   * @returns updated formatted image
   */
  formatConditional(
    formatedImage: string,
    jsonImg: Object,
    key: string
  ): string {
    let tempImg = formatedImage;
    try {
      tempImg = `${tempImg}${key}: ${jsonImg[key].value}\n`;
    } catch (error) {
      tempImg = `${tempImg}${key}: ${jsonImg[key]}\n`;
    }

    return tempImg;
  }

  /**
   * Pipe helper function to format filters from report
   * @param formatedImage current formatted image
   * @param jsonImg JSON version of original image
   * @param key key from jsonImg Object
   * @returns updated formatted image
   */
  formatReports(formatedImage: string, jsonImg: Object, key: string): string {
    let tempImg = formatedImage;
    for (const report of jsonImg[key]) {
      tempImg = `${tempImg}Report Name: ${
        report.report_name ?? report.reportName
      }\n`;
      for (const filter of report.filters) {
        if (filter.config?.hideFilter) {
          continue;
        } else if (filter.config?.filterType === FilterType.TIME) {
          if (filter.currentValue.label === TimeFilterLabel.RELATIVE) {
            tempImg = `${tempImg}${filter.name}: ${this.formateRelativeDate(
              filter.currentValue.time_range
            )}\n`;
          } else if (filter.currentValue.label === TimeFilterLabel.PREVIOUS) {
            tempImg = `${tempImg}${filter.name}: ${this.formatPreviousDate(
              filter.currentValue.time_range
            )}\n`;
          } else {
            tempImg = `${tempImg}${filter.config.name}: ${filter.currentValue.label}\n`;
          }
        } else {
          tempImg = `${tempImg}${filter.config.name}: ${filter.currentValue}\n`;
        }
      }
    }

    return tempImg;
  }

  /**
   * Pipe helper function to format Previous date type filters
   * @param date original date value
   * @returns formatted date value
   */
  formatPreviousDate(date: string): string {
    const regex = /\b(WEEK|MONTH|YEAR)\b/g;
    const period = regex.exec(date);

    return `Previous Calendar ${period ? period[1] : 'Invalid Entry'}`;
  }

  /**
   * Pipe helper function to format Relative date type filters
   * @param date original date value
   * @returns formatted date value
   */
  formateRelativeDate(date: string): string {
    const count = Math.abs(parseInt(date.split(',')[1], 10));
    const period = date.split(',')[2].split(')')[0].trim();

    return `Last ${count} ${period}(s)`;
  }
}

/**
 *
 */
@Pipe({
  name: 'scheduleNames'
})
export class ScheduleNamesPipe implements PipeTransform {
  /**
   * Formats list of all selected schedules for the UI
   * @param scheduleNames list of schedules
   * @returns formated string of schedules names selected
   */
  transform(scheduleNames: string[]): string {
    return scheduleNames.join(',\n\n');
  }
}

/**
 *
 */
@Pipe({
  name: 'executionReports'
})
export class ExecutionReportsPipe implements PipeTransform {
  constructor(private readonly datadogService: DatadogService) {}
  /**
   * Formats Reports for UI
   * @param execution execution log to format
   */
  transform(execution: ScheduleExecution): string {
    if (!execution.config) {
      return '';
    }
    try {
      if (execution.config.event_type === 'export') {
        return (
          execution.config.execution_name ??
          (execution.config as unknown as Export)?.views?.[0].execution_name
        );
      }
      const formattedReports = [];
      for (const report of execution.config.reports) {
        formattedReports.push(report.reportName);
      }

      return formattedReports.join(', ');
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error formatting execution reports pipe'
      });

      return '';
    }
  }
}

/**
 *
 */
@Pipe({
  name: 'translate'
})
export class CustomTranslatePipe implements PipeTransform {
  constructor(
    private readonly customTranslatorService: CustomTranslatorService,
    private readonly datadogService: DatadogService
  ) {}

  /**
   *
   * @param i18nKey
   */
  transform(i18nKey: string): string {
    try {
      const value =
        this.customTranslatorService.userLocaleData?.translations[i18nKey];

      return value;
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Error translating language. Key: ${i18nKey}`
      });
    }
  }
}
