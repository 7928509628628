import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { BaseModule, MaterialModule } from '@gravity-angular/base';
import { ComponentsModule } from '@gravity-angular/components';
import { LayoutModule } from '@gravity-angular/layout';
import { PagesModule } from '@gravity-angular/pages';
import { DateConversionsService } from './date-conversions/date-conversions.service';
import { IframeComponent } from './iframe/iframe.component';
import {
  AbbrevateTimeZonePipe,
  CustomTranslatePipe,
  RoleAuditImagePipe,
  RoleNamePipe,
  TimezoneConversionPipe,
  UserAuditImagePipe
} from './pipes/shared.pipe';

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { PaginatorService } from './services/custom-translator/custom-translator.service';
import { CommoditySwitcherService } from './commodity-switcher/commodity-switcher-service/commodity-switcher.service';
import { CommoditySwitcherComponent } from './commodity-switcher/commodity-switcher.component';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DatadogService } from './services/datadog-services/datadog.service';
import { StateService } from './services/state-services/state.service';
import { ExpandableContentComponent } from './expandable-content/expandable-content.component';

/**
 *
 */
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PagesModule,
    ComponentsModule,
    MatSortModule,
    BaseModule,
    FormsModule,
    LayoutModule,
    MaterialModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    DropdownModule,
    ScrollingModule
  ],
  providers: [
    DateConversionsService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true }
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MatPaginatorIntl, useClass: PaginatorService },
    CommoditySwitcherService,
    DatadogService,
    StateService
  ],
  declarations: [
    TimezoneConversionPipe,
    UserAuditImagePipe,
    RoleAuditImagePipe,
    RoleNamePipe,
    AbbrevateTimeZonePipe,
    CustomTranslatePipe,
    CommoditySwitcherComponent,
    ExpandableContentComponent
  ],
  exports: [
    CommonModule,
    BaseModule,
    FormsModule,
    PagesModule,
    MatSortModule,
    ComponentsModule,
    LayoutModule,
    ReactiveFormsModule,
    MaterialModule,
    TimezoneConversionPipe,
    UserAuditImagePipe,
    RoleAuditImagePipe,
    RoleNamePipe,
    AbbrevateTimeZonePipe,
    MatDatepickerModule,
    MatMomentDateModule,
    CustomTranslatePipe,
    CommoditySwitcherComponent,
    MatIconModule,
    DropdownModule,
    ScrollingModule,
    ExpandableContentComponent
  ]
})
export class SharedModule {}

export { DateConversionsService, IframeComponent };
