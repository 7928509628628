<div #content class="content"
     [ngClass]="{ 'hide-content': !expanded, 'show-content': expanded }"
>
  {{ data }}
</div>
<div class="show-more-wrapper" *ngIf="overflowing">
  <xg-button
    buttonType="stroked"
    buttonColor="primary"
    [text]="(expanded ? 'expandable-content_expand-button_show-less' : 'expandable-content_expand-button_show-more') | translate"
    (buttonClick)="expanded = !expanded"
  ></xg-button>
</div>