/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { get, put, del, patch, post, head } from 'aws-amplify/api';
import { uploadData, downloadData, list } from 'aws-amplify/storage';
import { GetCredentialsOptions } from 'aws-amplify/auth';
import { Cache } from 'aws-amplify/utils';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { environment } from '@env/environment';
import { ApiPayload } from '@common/models/api.model';
import { lastValueFrom } from 'rxjs';
import { CognitoServiceService } from '../cognito/cognito-service.service';
import { DatadogService } from '../../shared/services/datadog-services/datadog.service';
import { OidcConfigIds } from '@common/models/user-management.model';

/**
 * Service for Amplify/AWS operations.
 */
@Injectable({
  providedIn: 'root'
})
export class AmplifyService {
  amplifyIdp: string;
  amplifyConfig: AWSAmplifyConfig;

  constructor(
    private readonly cognitoServiceService: CognitoServiceService,
    public oidcSecurityService: OidcSecurityService,
    private readonly datadogService: DatadogService
  ) {
    this.amplifyIdp = OidcConfigIds.XGS;

    this.amplifyConfig = {
      API: {
        REST: {
          'customer-onboard': {
            endpoint: `https://${environment.domain}/api/customer-onboard`
          },
          API_XGS_biTOOL_Roles: {
            endpoint: `https://${environment.domain}/api/external-bi-access`
          },
          GUI_UDL_Usage: {
            endpoint: `https://${environment.domain}/api/usage`
          },
          API_UDL_BRE: {
            endpoint:
              environment.environmentName !== 'production'
                ? `https://${environment.domain}/api/business-rule-engine`
                : ''
          },
          API_UDL_UM_AUDIT_TRAIL: {
            endpoint: `https://${environment.domain}/api/um-history`
          },
          API_UDL_S2S_MGMT: {
            endpoint: `https://${environment.domain}/api/s2s-mgmt`
          },
          API_CLUSTERING_ANALYSIS: {
            endpoint:
              environment.environmentName !== 'production'
                ? `https://${environment.domain}/api/cluster-analysis`
                : ''
          },
          'user-tracking': {
            endpoint: `https://${environment.domain}/api/um-tracking`
          },
          'udl-scheduler': {
            endpoint: `https://${environment.domain}/api/scheduler`
          },
          'superset-api': {
            endpoint: `https://${environment.domain}/api/superset`
          },
          'exdl-data-import': {
            endpoint: `https://${environment.domain}/api/data-import`
          },
          'exdl-health-monitor': {
            endpoint: `https://${environment.domain}/api/health-monitor`
          }
        }
      },
      Storage: {
        S3: {
          bucket: environment.unloadBucket,
          region: 'us-east-1'
        }
      }
    };
    // configure amplify
    Amplify.configure(this.amplifyConfig, {
      Auth: {
        credentialsProvider: this.cognitoServiceService
      }
    });
  }

  /**
   * Sets the identity provider for Amplify.
   * @param idp - The identity provider to set.
   */
  setAmplifyIdp(idp: string): void {
    this.amplifyIdp = idp;
    this.cognitoServiceService.setAmplifyIdp(idp);
  }

  /**
   * Calls an API using the specified HTTP method, API name, and path.
   * @template T - The type of the response.
   * @param httpMethod - The HTTP method to use for the API call.
   * @param apiName - The name of the API to call.
   * @param path - The path of the API to call.
   * @param init - (Optional) The initial payload for the API call.
   * @param token - Whether to include the XGS token in the API call.
   * @returns A promise that resolves to the response of the API call.
   * @throws Will throw an error if the API call fails.
   */
  async callAPI<T>(
    httpMethod: string,
    apiName: string,
    path: string,
    init?: ApiPayload,
    token = true
  ): Promise<T> {
    let response = {};
    const payload = init ?? {};

    try {
      // If no XGS header present in payload, add it now to ensure all API requests have XGS tokens attached
      if (token && !payload.headers?.xgs) {
        const source$ = this.oidcSecurityService.getAccessToken();
        const xgsToken = await lastValueFrom(source$);
        payload.headers = {
          ...payload?.headers,
          xgs: xgsToken
        };
      }

      switch (httpMethod.toLowerCase()) {
        case 'get': {
          const restOperation = payload
            ? get({ path, apiName, options: payload })
            : get({ path, apiName });
          const { body } = await restOperation.response;
          response = await body.text();
          try {
            response = JSON.parse(response as string);
          } catch (error) {
            this.datadogService.errorTracking(error, {
              message: `Error parsing response from API call for ${apiName}/${path}`
            });
          }
          break;
        }

        case 'head': {
          const restOperation = head({ path, apiName });
          response = await restOperation.response;
          break;
        }

        case 'patch': {
          const restOperation = patch({
            path,
            apiName,
            options: payload
          });
          const { body } = await restOperation.response;
          response = await body.json();
          break;
        }

        case 'post': {
          const restOperation = post({
            path,
            apiName,
            options: payload
          });
          const { body } = await restOperation.response;
          response = await body.json();
          break;
        }

        case 'put': {
          const restOperation = put({
            path,
            apiName,
            options: payload
          });
          const { body } = await restOperation.response;
          response = await body.json();
          break;
        }

        case 'delete': {
          const restOperation = del({ path, apiName, options: payload });
          response = await restOperation.response;
          break;
        }
        default:
        // do nothing for now
      }
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: `Error occurred from API call for ${apiName}${path}`
      });
      throw error;
    }

    return response as T;
  }

  /**
   * Retrieves a file from an AWS S3 bucket.
   * @param key - The key of the object in the S3 bucket.
   * @param throwError - Whether to throw an error if the retrieval fails.
   * @param bucket - (Optional) The name of the S3 bucket. If provided, the Amplify configuration will be updated with this bucket.
   * @returns A promise that resolves to an object containing the body and eTag of the retrieved file.
   * @throws Will throw an error if the retrieval fails and throwError is true.
   */
  async getFileFromS3(
    key: string,
    throwError = false,
    bucket?: string
  ): Promise<any> {
    if (bucket) {
      this.updateAmplifyStorage(bucket);
      Amplify.configure(this.amplifyConfig);
    }

    let data = {};

    try {
      data = await downloadData({ path: key }).result;
      this.resetAmplifyConfig();
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred downloading file from S3'
      });
      this.resetAmplifyConfig();
      if (throwError) {
        throw error;
      }
    }

    return data;
  }

  /**
   * Updates the Amplify configuration to use the specified bucket and the appropriate region.
   * @param bucket - The name of the S3 bucket. The Amplify configuration will be updated with this bucket.
   */
  updateAmplifyStorage(bucket: string): void {
    this.amplifyConfig = {
      ...this.amplifyConfig,
      Storage: {
        ...this.amplifyConfig.Storage,
        S3: {
          region: bucket.includes('ca-central-1')
            ? 'ca-central-1'
            : 'us-east-1',
          bucket
        }
      }
    };
  }

  /**
   * Resets the Amplify configuration to use the unload bucket
   * specified in the environment and the 'us-east-1' region.
   *
   * If the current bucket in the Amplify configuration is not the unload bucket,
   * or the current region is not 'us-east-1', it updates the Amplify configuration to use
   * the unload bucket and 'us-east-1' region, and reconfigures Amplify with the updated configuration.
   *
   */
  resetAmplifyConfig(): void {
    if (
      this.amplifyConfig.Storage.S3.bucket !== environment.unloadBucket ||
      this.amplifyConfig.Storage.S3.region !== 'us-east-1'
    ) {
      this.amplifyConfig = {
        ...this.amplifyConfig,
        Storage: {
          ...this.amplifyConfig.Storage,
          S3: {
            region: 'us-east-1',
            bucket: environment.unloadBucket
          }
        }
      };
      Amplify.configure(this.amplifyConfig);
    }
  }

  /**
   * Returns the identity provider domain based on the current Amplify identity provider.
   * @returns Identity provider domain.
   */
  getIdentityProviderName(): string {
    return this.amplifyIdp === OidcConfigIds.XGS
      ? environment.xgsIdp
      : environment.goAiguaIdp;
  }

  /**
   * Signs in the user using OIDC and Cognito, and caches the federated info.
   * @returns A promise that resolves when the sign-in process is complete.
   * @throws Will log an error to the console if the sign-in process fails.
   */
  async signIn(): Promise<void> {
    try {
      const source$ = this.oidcSecurityService.getAccessToken(this.amplifyIdp);
      const token = await lastValueFrom(source$);

      const value =
        await this.cognitoServiceService.getCredentialsAndIdentityId(
          {} as GetCredentialsOptions
        );

      await Cache.setItem('federatedInfo', value, {
        expires: jwtDecode<JwtPayload>(token).exp * 1000
      });
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error getting AWS credentials'
      });
    }
  }

  /**
   * Signs out the user from AWS.
   */
  async awsSignOut(): Promise<void> {
    try {
      await Cache.removeItem('federatedInfo');
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error signing out AWS'
      });
    }
  }

  /**
   * Retrieves all files from an AWS S3 bucket.
   * @param throwError - Whether to throw an error if the retrieval fails.
   * @param bucket - (Optional) The name of the S3 bucket. If provided, the Amplify configuration will be updated with this bucket.
   * @returns - A promise that resolves to an object containing the data of all files in the bucket.
   * @throws Will throw an error if the retrieval fails and throwError is true.
   */
  async getAllFilesFromS3(throwError = false, bucket?: string): Promise<any> {
    let data = {};
    try {
      if (bucket) {
        this.updateAmplifyStorage(bucket);
        Amplify.configure(this.amplifyConfig);
      }
      data = await list({ path: '' });
      this.resetAmplifyConfig();
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred retrieving files from S3'
      });
      this.resetAmplifyConfig();
      if (throwError) {
        throw error;
      }
    }

    return data;
  }

  /**
   * Uploads a file to an AWS S3 bucket.
   * @param fileName - The name of the file to be uploaded.
   *    NOTE: The fileName should *not* include the bucket name, only the path to the object.
   *    Including the bucket name in the fileName will result in a duplicate bucket name in the path.
   * @param file - The file object to be uploaded.
   * @param throwError - Whether to throw an error if the upload fails.
   * @param bucket - (Optional) The name of the S3 bucket. If provided, the Amplify configuration will be updated with this bucket.
   * @param progressCallback - (Optional) A callback function to track the progress of the upload.
   * @returns A promise that resolves to an object containing the data of the uploaded file.
   * @throws Will throw an error if the upload fails and throwError is true.
   */
  async uploadFileToS3(
    fileName: string,
    file: File,
    throwError = false,
    bucket?: string,
    progressCallback?: any
  ): Promise<any> {
    let data = {};
    try {
      if (bucket) {
        this.updateAmplifyStorage(bucket);
        Amplify.configure(this.amplifyConfig);
      }
      data = await uploadData({
        path: `${fileName}`,
        data: file,
        options: {
          onProgress: progressCallback
        }
      }).result;
      this.resetAmplifyConfig();
    } catch (error) {
      this.datadogService.errorTracking(error, {
        message: 'Error occurred uploading file to S3'
      });
      this.resetAmplifyConfig();
      if (throwError) {
        throw error;
      }
    }

    return data;
  }
}

export interface S3Params {
  bucket: string;
  region: string;
  download: boolean;
  cacheControl: string;
}

export interface AWSAmplifyConfig {
  API: {
    REST: {
      [key: string]: {
        endpoint: string;
      };
    };
  };
  Storage: {
    S3: {
      bucket: string;
      region: string;
    };
  };
}
