import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

/**
 *
 */
@Component({
  selector: 'app-expandable-content',
  templateUrl: './expandable-content.component.html',
  styleUrls: ['./expandable-content.component.scss']
})
export class ExpandableContentComponent implements AfterViewInit {
  @Input() data: unknown;

  @ViewChild('content') content: ElementRef;

  // i18n strings
  showMore = $localize`:@@expandable-content_expand-button_show-more:Show more`;
  showLess = $localize`:@@expandable-content_expand-button_show-less:Show less`;

  expanded = false;
  overflowing = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  /**
   * Wait till view is initialized before checking overflow
   */
  ngAfterViewInit(): void {
    this.checkOverflow();
  }

  /**
   * Checks if verticle overflow is present in the content element
   */
  checkOverflow(): void {
    const elem = this.content.nativeElement;

    this.overflowing = elem.scrollHeight > elem.clientHeight;

    // Manually trigger the change detector so changes aren't made while it's checking
    this.cdr.detectChanges();
  }
}
